<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <component
                :is="getFormieComponent('select')"
                v-if="field.multi && Array.isArray(value)"
                v-model="value"
                :options="field.options"
                :is-multiselect="true"
                :placeholder="field.placeholder"
                :disabled="disabled"
            />
            <component
                :is="getFormieComponent('select')"
                v-else
                v-model="value"
                :options="field.options"
                :placeholder="field.placeholder"
                :disabled="disabled"
            />
        </component>
    </div>
</template>

<script setup>
const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

// Props to refs
const { field } = toRefs(props);

const value = defineModel({ type: [Array, String, Number, null], required: true });

onMounted(() => {
    if (field.value?.multi && !Array.isArray(value.value)) {
        value.value = [value.value];
    }
});
</script>
// NEEDS WORK // 1. Styling disabled option // 2. Multi-select
